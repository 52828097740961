import { useState } from "react"


function Conversations(props){
    return props.retrieveUserList()
    }


    

export default Conversations

 